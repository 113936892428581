footer {
    bottom: 0;
    position: sticky;
    width: 100%;
    display: grid;
    align-items: center;
    margin-top: 10px;
}

.ibx-h-pad-24 {
    padding-left: 24px;
    padding-right: 24px;
}

body {
    /*min-height: 100%;*/
    padding: 0;
    margin: 0;
}

.ibx-body-height {
    min-height: 82vh;
}

.ibx-header-height {
    height: 8vh;
}

.ibx-footer-height {
    height: 10vh;
}

.ibx-green {
    border-top: #618357 solid 2px;
    /*background-color: #618357;*/
}

.ibx-body-font {
    font-size: small;
}

.ibx-font-small {
    font-size: 8pt;
}

.ibx-m-top-8 {
    padding-top: 8px;
}

.ibx-form {
    width: 70%;
}

.ibx-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-floating.required > .form-control-plaintext ~ label::after,
/*.form-floating > .form-control:focus ~ label::after, */
.form-floating.required > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating.required > .form-select ~ label::after {
    content: " *" !important;
    color: red;
}